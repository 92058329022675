/* eslint-disable arrow-body-style */
import React from "react";
import { withPrefix, graphql } from "gatsby";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import cx from "classnames";
import WinnersSEO from "../../components/WinnersSEO";
import Layout from "../../layouts/index";
import WinnersOverview from "../../components/WinnersHero";

const renderWinner = ({ node }, fillRow = false) => {
  let LogoSection = null;
  if (node.url) {
    LogoSection = (
      <a href={node.url} target="_blank" rel="noopener noreferrer">
        <img
          className={cx("winner__logo", {
            "w-75 py-2": !fillRow,
            "w-75 w-lg-50 py-2": fillRow,
          })}
          src={withPrefix(node.logo)}
          alt=""
        />
      </a>
    );
  } else {
    LogoSection = (
      <img
        className={cx("winner__logo", {
          "w-75 py-2": !fillRow,
          "w-75 w-lg-50 py-2": fillRow,
        })}
        src={withPrefix(node.logo)}
        alt=""
      />
    );
  }

  return (
    <div
      key={node.id}
      className={cx("winner col-12 px-4", { "col-md-6": !fillRow })}
    >
      <div className="winner__bar" />
      <div className="winner__name">
        <span dangerouslySetInnerHTML={{ __html: node.name }} />
      </div>
      <div className="winner__subname">
        <span dangerouslySetInnerHTML={{ __html: node.subname }} />
      </div>
      <div className="winner__location">{node.location}</div>
      {LogoSection}
      <div className="winner__description">
        <span dangerouslySetInnerHTML={{ __html: node.description }} />
      </div>
    </div>
  );
};

const Winners = ({ data, location }) => {
  const earlyStageWinners = data.allEarlyStageWinnersJson.edges;
  const matureStageWinners = data.allMatureStageWinnersJson.edges;
  const choiceAwardWinner = data.allChoiceAwardWinnerJson.edges;
  const risingStarAwardWinner = data.allRisingStarAwardWinnerJson.edges;
  return (
    <Layout bodyClass="page-winners" location={location}>
      <WinnersSEO />
      <WinnersOverview />

      <div className="container px-2 py-3 py-md-4">
        <div className="row justify-content-start">
          <div className="col-12">
            <div className="">
              <p className="py-1">
                <a
                  className="text-blue text-underline"
                  href="https://www.imaginablefutures.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Imaginable Futures
                </a>{" "}
                and{" "}
                <a
                  className="text-blue text-underline"
                  href="https://www.luminafoundation.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lumina Foundation
                </a>,{" "}
                along with ten{" "}
                <a
                  className="text-blue text-underline"
                  href="/our-partners"
                  target="_blank"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Overview",
                      action: "Click",
                      label: `Partner Organziations`,
                    });
                  }}
                >
                  leading partner organizations
                </a>,{" "}
                are thrilled to announce the <strong>15 winners</strong> of the
                Rise Prize.
              </p>
              <p className="py-1">
                A total of $1.55 million in combined prizes was awarded to
                innovative ideas and solutions with the greatest potential for
                impact in supporting the postsecondary success of student
                parents. Winners range from early-stage pilots to mature
                solutions across for-profit and nonprofit organizations, as well
                as higher education institutions.
              </p>
              <p className="py-1">
                The Rise Prize was{" "}
                <a
                  className="text-blue text-underline"
                  href="https://www.imaginablefutures.com/learnings/1-million-rise-prize-announced/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  launched in early 2020
                </a>{" "}
                to elevate the unique needs of student parents and increase the
                number of impactful and effective solutions accelerating their
                postsecondary success. The original award amount of $1 million
                to 10 organizations was increased to $1.55 million to 15
                organizations, in response to the COVID-19 pandemic and in
                recognition of the added urgency to support student parents.
              </p>
              <p className="py-1 mb-0">
                <strong>And the winners are…</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center mt-2 mt-md-4">
          <div className="stage choice-award choice-prize">
            <div className="stage-header">
              <img
                src={withPrefix("/illustrations/choice-award.svg")}
                alt="Riser Choice Award"
              />
              <h4 className="font-weight-bold">Risers’ Choice Award</h4>
            </div>
            <div className="stage-content">
              <div className="container px-2 py-3 py-md-4">
                <p className="">
                  $100,000 awarded to a winner selected by the two student
                  parent judges who brought an authentic understanding to the
                  challenges that student parents face.
                </p>
              </div>
            </div>
          </div>
          <div className="container winners-list choice-award">
            {choiceAwardWinner.map(edge => renderWinner(edge, true))}
          </div>
        </div>
        <div className="row justify-content-center mt-2 mt-md-4">
          <div className="stage choice-award rising-star">
            <div className="stage-header">
              <img
                src={withPrefix("/illustrations/rising-star.svg")}
                alt="Riser Choice Award"
              />
              <h4 className="font-weight-bold">Rising Star Award</h4>
            </div>
            <div className="stage-content">
              <div className="container px-2 py-3 py-md-4">
                <p className="">
                  $50,000 awarded to a winner that showed innovation in
                  supporting the unique role of fathers as student parents.
                </p>
              </div>
            </div>
          </div>
          <div className="container winners-list rising-star">
            {risingStarAwardWinner.map(edge => renderWinner(edge, true))}
          </div>
        </div>
        <div className="row justify-content-center mt-2 mt-md-4">
          <div className="stage choice-award early-stage">
            <div className="stage-header">
              <img
                src={withPrefix("/illustrations/early-stage.svg")}
                alt="Early Stage"
              />
              <h4 className="font-weight-bold">Early Stage</h4>
            </div>
            <div className="stage-content">
              <div className="container px-2 py-3 py-md-4">
                <p className="">
                  $50,000 awarded to each of the 8 early stage winners—newly
                  launched ideas in pilot/market research mode
                </p>
              </div>
            </div>
          </div>
          <div className="container winners-list">
            {earlyStageWinners.map(edge => renderWinner(edge))}
          </div>
        </div>
        <div className="row justify-content-center mt-2 mt-md-4">
          <div className="stage choice-award mature-stage">
            <div className="stage-header">
              <img
                src={withPrefix("/illustrations/mature-stage.svg")}
                alt="Mature Stage"
              />
              <h4 className="font-weight-bold">Mature Stage</h4>
            </div>
            <div className="stage-content">
              <div className="container px-2 py-3 py-md-4">
                <p className="">
                  $200,000 awarded to each of the 5 mature stage
                  winners—solutions with demonstrated track record of impact and
                  proven ability to significantly scale.
                </p>
              </div>
            </div>
          </div>
          <div className="container winners-list mature-stage">
            {matureStageWinners.map(edge => renderWinner(edge))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allEarlyStageWinnersJson {
      edges {
        node {
          id
          name
          subname
          location
          logo
          url
          description
        }
      }
    }
    allMatureStageWinnersJson {
      edges {
        node {
          id
          name
          location
          logo
          url
          description
        }
      }
    }
    allChoiceAwardWinnerJson {
      edges {
        node {
          id
          name
          location
          logo
          url
          description
        }
      }
    }
    allRisingStarAwardWinnerJson {
      edges {
        node {
          id
          name
          subname
          location
          logo
          url
          description
        }
      }
    }
  }
`;

export default Winners;
