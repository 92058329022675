import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, withAssetPrefix } from 'gatsby';

const SEO = props => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const description = [];
      if (!props.meta.some(e => e.name === 'description')) {
        description.push({ name: 'description', content: "Imaginable Futures, a venture of The Omidyar Group, and Lumina Foundation, along with a group of leading partner organizations and seven prominent judges, announced the 15 winners of the Rise Prize, awarding $1.55 million in combined prizes to the best innovative ideas supporting the postsecondary success of student parents." });
      }

      return (
        <Helmet
          htmlAttributes={{
            lang: props.lang,
          }}
          title="15 Winners Awarded Rise Prize"
          titleTemplate={`%s - ${data.site.siteMetadata.title}`}
          meta={[
            { name: "google-site-verification", content: "9oSfrKagBkUfHv2yKiAezbZeLnkhJM7Iv_R45mYnctc" },
            { property: "og:type", content: "website" },
            { property: "og:url", content: "https://theriseprize.com" },
            { property: "og:title", content: "15 Winners Awarded Rise Prize" },
            { property: "og:description", content: `Imaginable Futures, a venture of The Omidyar Group, and Lumina Foundation, along with a group of leading partner organizations and seven prominent judges, announced the 15 winners of the Rise Prize, awarding $1.55 million in combined prizes to the best innovative ideas supporting the postsecondary success of student parents.` },
            { property: "og:image", content: `https://theriseprize.com${withAssetPrefix('social/winner-twitter-image.jpg')}` },
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:title", content: "15 Winners Awarded Rise Prize" },
            { name: "twitter:description", content: `Imaginable Futures, a venture of The Omidyar Group, and Lumina Foundation, along with a group of leading partner organizations and seven prominent judges, announced the 15 winners of the Rise Prize, awarding $1.55 million in combined prizes to the best innovative ideas supporting the postsecondary success of student parents.` },
            { name: "twitter:image", content: `https://theriseprize.com${withAssetPrefix('social/winner-twitter-image.jpg')}` },
            { name: "google-site-verification", content: "9oSfrKagBkUfHv2yKiAezbZeLnkhJM7Iv_R45mYnctc" },
            ...props.meta,
            ...description,
          ]}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query WinnerSeoQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
