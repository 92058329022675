import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image-es5';

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "winners.jpg" }) {
          childImageSharp {
            fluid( maxWidth: 12000 ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor="#ECC047"
        >
          <div className="hero-content">
            <h1>
              Congratulations to winners <br/> of the Rise Prize!
            </h1>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  background-repeat: no-repeat !important;
  background-size: 100vw !important;
  width: 100%;
  color: #000;
  min-height: 360px !important;
  object-fit: cover;

  background-position: 10% 100% !important;

  @media (min-width: 0px) {
    background-size: 164% !important;
    background-position: 72% 94% !important;
  }
  @media (min-width: 576px) {
    background-size: 155% !important;
    background-position: 59% 80% !important;
    min-height: 600px !important;
  }
  @media (min-width: 768px) {
    background-size: 142% !important;
    background-position: 73% 99% !important;
    min-height: 500px !important;
  }
  @media (min-width: 992px) {
    background-size: 122% !important;
    background-position: 68% 99% !important;
    min-height: 500px !important;
  }
  @media (min-width: 1200px) {
    background-size: 100% !important;
    background-position: 6% 83% !important;
    min-height: 700px !important;
  }

  &::before,
  &::after {
    @media (min-width: 0px) {
    background-size: 164% !important;
    background-position: 72% 94% !important;
  }
  @media (min-width: 576px) {
    background-size: 155% !important;
    background-position: 59% 80% !important;
    min-height: 600px !important;
  }
  @media (min-width: 768px) {
    background-size: 142% !important;
    background-position: 73% 99% !important;
    min-height: 500px !important;
  }
  @media (min-width: 992px) {
    background-size: 122% !important;
    background-position: 68% 99% !important;
    min-height: 500px !important;
  }
  @media (min-width: 1200px) {
    background-size: 100% !important;
    background-position: 6% 83% !important;
    min-height: 500px !important;
  }
  }

  h1 {
    font-family: 'Alfa Slab One';
    letter-spacing: 1px;
    @media (max-width: 1200px) {
      font-size: 2.2rem;
    }
    @media (max-width: 992px) {
      font-size: 2rem;
    }
    @media (max-width: 768px) {
      font-size: 2rem;
    }
    @media (max-width: 575px) {
      font-size: 1.35rem;
    }
  }

  .hero-content {
    text-align: left;
    @media (min-width: 0px) {
      padding: 1rem;
      max-width: 100%;
    }
    @media (min-width: 576px) {
      padding: 2rem;
      max-width: 100%;
    }
    @media (min-width: 768px) {
      padding: 2rem;
      max-width: 700px;
    }
    @media (min-width: 992px) {
      padding: 2rem;
      max-width: 700px;
    }
    @media (min-width: 1200px) {
      padding: 3rem;
      max-width: 800px;
      position: relative;
      bottom: 0;
    }
  }
`;

export default StyledBackgroundSection;
